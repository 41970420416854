import * as React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import CustomTable from '../../table/customTable';
import ActionsDots from '../../table/actionsDots/actionsDots';
import { ReactComponent as EditIcon } from '../../../assets/icons/general/editIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/general/deleteIcon.svg';
import axios from 'axios';

export default function SitesTable({ data }) {

  const handleRestart = (siteName) => {
    let url = "";
    if (siteName === "localhost") {
      url = "http://localhost/admin/restart_server";
    } else {
      url = "https://" + siteName + ".carteav.com/admin/restart_server";
    }
  
    axios.post(url, {}, { withCredentials: true })
      .then(response => {
        if (response.status === 200) {
          alert(`Restart request sent for site: ${siteName}`);
        }
      })
      .catch(error => {
        console.error(`Failed to restart site: ${siteName}`, error);
        console.error('Error details:', error.response ? error.response.data : error.message);
        alert(`Failed to restart site: ${siteName}`);
      });
  };
  

  const columns_sites = [
    {
      field: 'id',
      width: 70,
      type: "number",
      headerAlign: 'center',
      hide: true,
      renderHeader: () => <strong>{'Site ID'}</strong>,
    },
    {
      field: 'site_name',
      width: 240,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <b>{params.row.site_name}</b>,
      renderHeader: () => <strong>{'Sites Name'}</strong>,
    },
    {
      field: 'server_version',
      headerName: 'SW Version',
      headerAlign: 'center',
      align: 'center',
      width: 140,
      renderCell: (params) => params.row.versions?.server_version || <CloseIcon color="error" />,
      renderHeader: () => <strong>{'Server Version'}</strong>,
    },
    {
      field: 'db_version',
      headerAlign: 'center',
      align: 'center',
      width: 140,
      renderCell: (params) => params.row.versions?.db_version || <CloseIcon color="error" />,
      renderHeader: () => <strong>{'DB Version'}</strong>,
    },
    {
      field: 'app_version',
      headerName: 'App Version',
      headerAlign: 'center',
      align: 'center',
      width: 140,
      renderCell: (params) => params.row.versions?.app_version || <CloseIcon color="error" />,
      renderHeader: () => <strong>{'App Version'}</strong>,
    },
    {
      field: 'cart_version',
      headerName: 'SW Version',
      headerAlign: 'center',
      align: 'center',
      width: 140,
      renderCell: (params) => params.row.versions?.cart_version || <CloseIcon color="error" />,
      renderHeader: () => <strong>{'Cart Version'}</strong>,
    },
    {
      field: 'malfunction_status',
      headerAlign: 'center',
      align: 'center',
      width: 180,
      renderCell: (params) => params.row.malfunction?.status || <CloseIcon color="error" />,
      renderHeader: () => <strong>{'Malfunction'}</strong>,
    },
    {
      field: 'restart',
      headerAlign: 'center',
      align: 'center',
      width: 80,
      renderCell: (params) => {
        const { row } = params;
        return (
          <RestartAltIcon
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => handleRestart(row.site_name)}
          />
        );
      },
      renderHeader: () => <strong>{'Restart'}</strong>,
    },
    {
      field: 'edit',
      headerAlign: 'center',
      sortable: false,
      width: 10,
      align: 'center',
      renderHeader: () => '',
      renderCell: (params) => {
        const { row } = params;
        return (
          <ActionsDots
            items={
              <>
                <div className={"adminActionsItem"} onClick={(e) => {
                  e.stopPropagation();
                  row.edit(row);
                }}>
                  <span><EditIcon /> </span> Edit site
                </div>

                {row?.db_backup_files &&
                  <div className={"adminActionsItem"} onClick={(e) => {
                    e.stopPropagation();
                    row.changeDBVersion(row);
                  }}>
                    <span><EditIcon /> </span> Replace DB
                  </div>
                }
                <div className={"adminActionsItem"} onClick={(e) => {
                  e.stopPropagation();
                  row.delete(row);
                }}>
                  <span><DeleteIcon /></span>
                  <span style={{ color: 'var(--red)' }}>Remove site</span>
                </div>
              </>
            }
          />
        );
      },
    },
  ];

  return (
    <CustomTable
      data={data}
      columns={columns_sites}
      sortModel={null}
      onSortModelChange={null}
      tableActions={null}
    />
  );
}
