import * as React from 'react';
import { DataGrid} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';

const columns = [
  { 
  field: 'id',
  width: 70,
  type: "number",
  headerAlign: 'center',
  options: { sortDirection: 'DESC' },
  renderHeader: () => (
  <strong>
    {'ID'}
  </strong>
  ), 
  },
  {
    field: 'log_dt',
    width: 260,
    headerAlign: 'center',
    renderHeader: () => (
      <strong>
      {'Time'}
    </strong>
  ),  
},
  {
    field: 'log_description',
    width: 600,
    headerAlign: 'center',
    renderHeader: () => (
      <strong>
        {'Description'}
      </strong>
    ),
  },
    { 
        field: 'log_level',
        width: 180,
        headerAlign: 'center',
        renderHeader: () => (
        <strong>
        {'Level'}
        </strong>
        ),
    },

    { 
        field: 'log_subject',
        width: 180,
        headerAlign: 'center',
        renderHeader: () => (
        <strong>
            {'Subject'}
        </strong>
        ),
    },
 

];


export default function SiteLogsTable({data  , level }) {
    const bgColor = level === 'error' ? '#e57373': level==='warn' ?'#ffc570' :''
  return (
    <div style={{position:'absolute', width: '70%', left: '15%' ,backgroundColor: bgColor,opacity:0.9,
}}>
   <Box
      sx={{
        height: 600,
        width: '100%',
        '& .active': {
          backgroundColor: 'rgba(157, 255, 118, 0.49)',
          fontWeight: 'bold',
        },
        '& .not_active': {
          backgroundColor: '#d47483',
          fontWeight: 'bold',
        },
      }}
    >
     {data ?  <DataGrid 
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                  '& .MuiDataGrid-cell':{
                      textAlign:'center',
                      fontSize: 16,
                      justifyContent:'center',
                      borderColor: 'primary.light',
                  },
                }}
                rows={data}
                columns={ columns}
                getCellClassName={(params) => {
                  if (params.field === 'active'){
                    return params.value  ? 'active' : 'not_active';
                  } else {
                    return '';
                  }
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'id', sort: 'desc' }],
                  },
                }}
                autoPageSize
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
      /> : null } 
      </Box>
    </div>
  );
}
