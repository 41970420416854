import React from 'react';

const PageHeader = ({text,buttonsContainer}) => {
    return (
        <div className='header_container' >
            <div className="header_text">{text}</div>
            <div className="header_buttons">{buttonsContainer}</div>
        </div>
    );
};

export default PageHeader;