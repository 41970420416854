import axios from 'axios';
import { serverHost } from '../../../redux/reducers/reducer';

export default  async function GetSitesList(){
    const server =serverHost();
    try {
      const response = await   axios.get(server +'/site/all')
      if (response.status === 200){
        const dataArr = response.data; //[{site_name:',id:uuid}]
        const sites =[]
        for (let element of dataArr){
          sites.push(element.site_name)
        }
        
        return sites;
      }
        return (null)
    }catch(err){
        console.error(err.message)
        return null;
    }
}