import React from 'react';
import classNames from 'classnames';

import "./buttons.css";


function GenralConfirmOrCancelButton({onClick,text,type,id,addedStyles=null}) {
    
    const addedClass = type === 'confirm' ? 'CustomAddAndEditModal-save-button' :'CustomAddAndEditModal-cancel-button';
    const buttonClasses = classNames('CustomAddAndEditModal-button', addedClass);
    
  return (
    <button 
      id={id ? id :null} 
      className={buttonClasses}   
      style={{...addedStyles}}
      onClick={(e)=>{
        e.preventDefault();
        onClick()
      }}
    >
       {text}
    </button> 
);
}
export default GenralConfirmOrCancelButton;