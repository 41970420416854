
export const ROLES ={
    ADMIN:'Admin',
    MANAGER:'Manager',
    OPERATOR:'Operator',
    SITE_MANAGER:'SiteManager',
    CARTEAV_ADMINISTRATOR:'CarteavAdministrator'

}

export const SCOPES = {
    canCreate: "can-create",
    canEdit: "can-edit",
    canDelete: "can-delete",
    canView: "can-view"
};

export const PERMISSIONS = {
    [ROLES.OPERATOR]: [SCOPES.canView],
    [ROLES.SITE_MANAGER] :[SCOPES.canView],
    [ROLES.MANAGER]: [SCOPES.canView, SCOPES.canEdit],
    [ROLES.ADMIN]: [
      SCOPES.canView,
      SCOPES.canEdit,
      SCOPES.canDelete
    ],
    [ROLES.CARTEAV_ADMINISTRATOR]:[
        SCOPES.canView,
        SCOPES.canEdit,
        SCOPES.canCreate,
        SCOPES.canDelete
    ]
};
export const ALLOWED_COMPONENTS = {// specific components that are allowed for specific roles
  'Site params': [ROLES.OPERATOR,ROLES.ADMIN] ,
  'App params': [ROLES.OPERATOR],
  'Admin params': [ROLES.ADMIN]
}

export const ROLES_ARRAY = Object.values(ROLES);

//List of allowed users roles for each protected page 
export const ROLES_PAGES ={
    register :[ROLES.ADMIN],
    dashboard :[ROLES.ADMIN,ROLES.OPERATOR,ROLES.MANAGER,ROLES.SITE_MANAGER],
    logs:[ROLES.ADMIN,ROLES.OPERATOR,ROLES.MANAGER,ROLES.SITE_MANAGER],
    dashboard_siteConfig:[ROLES.ADMIN,ROLES.MANAGER,ROLES.SITE_MANAGER],
    not_allowed:[ROLES.ADMIN,ROLES.OPERATOR,ROLES.MANAGER,ROLES.SITE_MANAGER],
    sites:[ROLES.ADMIN,ROLES.OPERATOR,ROLES.MANAGER,ROLES.SITE_MANAGER]
}

export const SITES_LIST=[
    'develop',
    'company',
    'pilot',
    'demo'
]
