import axios from 'axios';
import { serverHost} from '../../redux/reducers/reducer';

export default  async function RefreshToken(){
    const server =serverHost()
    axios.defaults.withCredentials= true
    try {
    const response = await axios.post(`${server}/admin/token`)
    
    if (response.status === 200){
        const data = response.data.data
        const accessToken = data?.accessToken;
        axios.defaults.headers.common['authorization'] =`Bearer ${accessToken}`
        const userName = data?.userName;
        const user ={   
          accessToken,
          userName,
       };
        return user;
    }
      return (null)
    }catch(err){
        console.error(err.message)
        return null;
        }
}
