import {useEffect,useRef,useState} from "react";
import useAuth from "../../hooks/Auth/useAuth";
import { useHistory } from "react-router-dom";
import {
    Grid,
    Paper,
    Button,
    Typography
  } from '@mui/material/';
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import { useSelector } from 'react-redux'

import styles from './login.module.css'; 
import GenralConfirmOrCancelButton from "../../components/buttons/generalConfirmOrCancelButton";


export default function Login(){
    const history = useHistory()
    const serverHost = useSelector((state) => state.serverHost)
    const {setAuth,auth} = useAuth()
    const [userName,setUserName] = useState("");
    const [password,setPassword] = useState("");
    const [showPassword,setShowPassword] = useState(false);
    const [error,setError] = useState('');
    const errRef = useRef();


    const handleChange = (type,event) => {
        setError("")
        switch (type) {
            case "userName":
                setUserName(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
            default:
                break;
        }
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


      useEffect(()=>{
        if(auth && auth.accessToken && auth.userName ){
            history.push("/users")
        }
        return ()=>{
            sessionStorage.removeItem("currentPath");
        }
    },[auth])

      const handleSubmit =async () =>{
         
        try {
            axios.defaults.withCredentials = true;
            const response = await axios.post(`${serverHost}/admin/login`,{'password':password,'userName':userName});
            const accessToken = response?.data?.accessToken;
            axios.defaults.headers.common['authorization'] =`Bearer ${accessToken}`
            const user ={
                accessToken,
                userName,
            };
            setAuth(user);
        }catch(err){
            setError("One or more are incorrect !")
            errRef.current.focus();
        }

      }
      return (
        <div onKeyDown={(e) => {
            if (e.key === "Enter") {
                handleSubmit();
            }}}
        >   
            <Paper className={styles.paperContainer}>
            
                    <form 
                        className="center_column"
                    >                    
                    <Grid style={{marginBottom:'1rem'}}>
                        <InputLabel sx={{color:`var(--grey)`}} htmlFor="username"><b>User Name</b></InputLabel>
                        <FilledInput
                            id="outlined-adornment-userName"
                            type={"text"}
                            value={userName}
                            autoComplete="userName"
                            htmlFor="username"
                            required
                            onChange={(e)=>handleChange('userName',e)}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="user "
                                edge="end"
                                disabled
                                >
                               <AccountCircle />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid style={{marginBottom:'1rem'}}>
                        <InputLabel htmlFor="password" sx={{color:`var(--grey)`}}><b>Password</b></InputLabel>
                        <FilledInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            required
                            onChange={(e)=>handleChange('password',e)}
                            autoComplete="password"
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=>{setShowPassword(!showPassword)}}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    disableFocusRipple
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Password"
                        />
                    
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:'1rem'}}>
                    <Typography ><b ref={errRef} className={styles.errorText}> {error}</b></Typography>
                    </Grid>
                    <Grid >
                        <GenralConfirmOrCancelButton
                            type={'confirm'} 
                            onClick={handleSubmit} 
                            text={'Login'} 
                            id={'loginButton'} 
                            addedStyles ={{height:'2.7rem',width:'8rem',fontSize:'1.2rem'}}
                        />
                    </Grid>
                    </form>
            </Paper>

            </div>
    );

}