import { createContext, useEffect, useState } from "react";
import RefreshToken from "../../hooks/Auth/refreshToken";
import getSitesList from "../../hooks/Auth/utils/sitesList";
const AuthContext = createContext({});

export const AuthProvider = ({ children  }) => {
      
    const [auth, setAuth] = useState({});
    const [sitesList , setSitesList] = useState([])
    const [loading,setLoading] = useState(true)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    
    
    useEffect(() => {
        (async () => {
            const refresh_response = await RefreshToken();
            setLoading(false);
        
            if (refresh_response) {
                setAuth(refresh_response);
            }
         
    
        })();
    }, []);

    const getSitesListFunction = async ()=>{
        const sites_response = await getSitesList();
            
        if (sites_response) {
            setSitesList(sites_response);
        }
    }


    if(!loading){
    return (
        <AuthContext.Provider value={{ windowWidth ,sitesList,auth, setAuth ,setSitesList,getSitesListFunction}}>
            {children}
        </AuthContext.Provider>
    )
    }else{
        return null;
    }
}

export default AuthContext;