import React from 'react'
import { ReactComponent as Horizontal3Points } from "../../../assets/icons/general/horizontal3Points.svg";

import "./actionDots.css"


function ActionsDots({items}) {
  return (
        <div id='adminActionHover'>
            <Horizontal3Points />
          <div className='hiddenAdminActionsContainer'>
            <div   className={"adminActionsContainer"}>
              {/* ITEM EXAMPLE
                
                <div className={"adminActionsItem"}
                  onClick={(e)=>{
                    e.stopPropagation();
                    row.edit(row.id,row.name,row.cart_seats,row.cart_description,row.cart_pic);
                  }}
                  >
                  <span style={{position:'relative',left:'15px',marginRight:'6px'}}><EditCartIcon /></span> <span style={{position:'relative',left:'27px'}}>Edit cart info</span>
                </div> 
              */}

                {items}
            </div>
          </div>
        </div>
    );
}

export default ActionsDots