import * as React from 'react';
import CustomTable from '../../table/customTable';
import ActionsDots from '../../table/actionsDots/actionsDots';
import { ReactComponent as EditIcon } from '../../../assets/icons/general/editIcon.svg';


const columns_customers = [
  { 
  field: 'id',
  width: 350,
  headerAlign: 'center',
  hide:true,
  renderHeader: () => (
  <strong>
    {'User Id'}
  </strong>
  ), 
  },
  {
    field: 'userName',
    width: 160,
    headerAlign: 'center',
    renderHeader: () => (
      <strong>
      {'User Name'}
    </strong>
  ),  
},
  {
    field: 'firstName',
    width: 160,
    headerAlign: 'center',
    renderHeader: () => (
      <strong>
        {'First Name'}
      </strong>
    ),
  },
  {
    field: 'lastName',
    width: 160,
    headerAlign: 'center',
    renderHeader: () => (
      <strong>
        {'Last Name'}
      </strong>
    ),
  },
  { 
    field: 'role',
    width: 120,
    headerAlign: 'center',
    renderHeader: () => (
    <strong>
      {'Role'}
    </strong>
    ),
    },
    {
      field: 'sites_list',
      width: 150,
      headerAlign: 'center',
      renderHeader: () => (
        <strong>
          {'Sites List'}
        </strong>
      ),
    },
    {
      field: 'email',
      width: 240,
      headerAlign: 'center',
      renderHeader: () => (
        <strong>
          {'Email'}
        </strong>
      ),
    },
  {
    field: 'edit',
    headerAlign: 'center',
    sortable: false,
    width:10,
    align:'center',
    renderHeader: () => '',
    renderCell: (params) => {
      const {row} = params;

      return (
        <ActionsDots 
          items={
            <>
              <div className={"adminActionsItem"}
                onClick={(e)=>{
                  e.stopPropagation();
                  row.edit(row);
                }}
              >
                  <span><EditIcon /> </span> Edit user
              </div> 
            </>
          }
        />
      )
    },
  },
];

export default function UsersTable({data }) {

  return (
    <CustomTable
      data={data}
      columns={columns_customers}
      sortModel={null}
      onSortModelChange={null}
      tableActions={null}
    />
  );
}
