
import  {useEffect,useState } from 'react'
import { SCOPES } from '../../redux/context/allowedTypes';
import SiteLogsTable from '../../components/Dashboard/Logs/LogsTables';
import Tab from '@mui/material/Tab';
import axios from 'axios'
import Tabs from '@mui/material/Tabs';
import useAuth from '../../hooks/Auth/useAuth';
import { serverHost } from '../../redux/reducers/reducer';
export default function Logs(){
    const {auth} = useAuth()
   const serverhost = serverHost()
    const [errorData,setErrorData] = useState([])
    const [warnData,setWarnData] = useState([])
    const [infoData,setInfoData] = useState([])
    const [tabValue , setTabValue] = useState('info')
    
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(()=>{
        onReload()
    },[])

    const onReload =() =>{
        axios.get(serverhost +'/site/getAllLogs')
        .then(res=>{
            const logsArray = res.data
            const warn =[]
            const info =[]
            const error =[]
            
            for (const elem of logsArray){
                const currTime = elem.log_dt;
           
                 elem.log_dt =`${new Date(currTime).getDate()}/${new Date(currTime).getMonth() +1}/${new Date(currTime).getFullYear()}  ${new Date(currTime).toLocaleTimeString()} `   
                switch (elem.log_level)
                {
                    case 'Info':
                        info.push(elem);
                        break;
                    case 'Error':
                        error.push(elem);
                        break;
                    case 'Warn':
                        warn.push(elem);
                        break;
                    default :
                        break;

                }
            }
            setErrorData(error)
            setWarnData(warn)
            setInfoData(info)
        }).catch(err=>{
            setErrorData([])
            setWarnData([])
            setInfoData([])
        })
    }

    return(
        <div
        >        
            <div style={{
                    position:'fixed', 
                    left:'50%',
                    top:'10vh',
                    transform: 'translate(-50%, 50%)'
                }}
            >
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="tabs"
                    textColor="inherit"
                >
                    <Tab 
                        value="info"
                        label="Info" 
                        wrapped 
                        style={{
                            fontFamily: 'Open Sans', 
                            fontWeight:'bold', 
                            fontSize:'1.2em', 
                            color:'#115b78'
                        }}     
                    />
                    <Tab 
                        value="warn" 
                        label="Warning" 
                        style={{
                            fontFamily:'Open Sans', 
                            fontWeight:'bold',
                            fontSize:'1.2em',
                            color:'#115b78'
                        }} 
                    />
                    <Tab 
                        value="error"
                        label="Error" 
                        style={{
                            fontFamily:'Open Sans', 
                            fontWeight:'bold',
                            fontSize:'1.2em',
                            color:'#115b78'
                        }} 
                    />
                </Tabs>
            </div>
                <div style={{
                        position:'relative',
                        top:'20vh'
                    }}
                >
                    <SiteLogsTable 
                        data={tabValue ==='info' ? infoData :tabValue ==='error' ?errorData: warnData}
                        level={tabValue}     
                    />
            </div>
        </div>
    )
}