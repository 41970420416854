import * as React from 'react';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Logo from "../../../assets/icons/carteavicon-png.png"
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import DataObjectIcon from '@mui/icons-material/DataObject';
import useAuth from '../../../hooks/Auth/useAuth';
import Fab from '@mui/material/Fab';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import axios from 'axios';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux'
import {ROLES_PAGES} from '../../../redux/context/allowedTypes';
import { compose } from '@mui/system';
import { useLocation } from "react-router-dom";

import styles from"./appBar.module.css"

const settings = [ 'Logout'];
const pages = [
  {
    display: 'Users',
    icon: <SettingsIcon />,
    to: '/users',
    section: 'users',
},
{
  display: 'Sites',
  icon: <DataObjectIcon />,
  to: '/sites',
  section: 'sites',
},
{
  display: 'Logs',
  icon: <DataObjectIcon />,
  to: '/logs',
  section: 'logs',
},
{
  display: 'Register Admin',
  icon: <PersonAddAltIcon />,
  to: '/register_admin',
  section: 'register',
},
]
const ResponsiveAppBar = () => {
  const {auth} = useAuth();
  const {setAuth} = useAuth();
  const serverHost = useSelector((state) => state.serverHost)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openSites,setOpenSites] = React.useState(false);
  const history = useHistory()
  const location = useLocation();
  React.useEffect(()=>{
    if(auth?.accessToken && auth?.userName ){
      history.push('/users');
    }
  },[])
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (to) => {
    history.push(to);
  };



  const handleChnageSite=async (event,site_name,sites_list)=>{
    event.preventDefault();
    const newArray=[site_name]
    sites_list.forEach(element => {
      if(element !== site_name )
          newArray.push(element);
    });
    localStorage.setItem('site', site_name);
    setAuth({...auth,relevant_site: site_name})
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
const handleNavToPage = (to)=>{
  history.push(to);
  setAnchorElNav(null)
}

const handleLogout=  async (event) => {
  event.preventDefault();
  try {
    const response = await axios.post(`${serverHost}/admin/logout` ,{ withCredentials: true })
    console.log(response);
    setAuth({})
    setAnchorElNav(null)
    setOpenSites(false)
    setAnchorElUser(null)
    handleCloseNavMenu('/login');
  } catch (error) {
    console.log(error);
  }
}
  return (
    <AppBar 
      position='sticky' 
      elevation={0} 
      className={ styles.admin_appBar} // Apply CSS module class
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <img src={Logo} alt='logo' height={'40px'} width={'40px'} style={{marginRight:'1vw'}}></img>
          <Typography
            variant="h4"
            noWrap
            component="a"
            href={auth.userName ?"/users":'/login'}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 600,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            CARTEAV
          </Typography>
          

          {auth.userName ? <PopupState variant="popover" popupId="popup-sites_list" >
            {(popupState) => (
              <React.Fragment>
                <Typography   
                 variant="h4"
                  noWrap
                  component="a"
                  sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 600,
                    letterSpacing: '.3rem',
                    
                    color: '#009DDC',
                    textDecoration: 'none',
                  }}>
                   SERVER
                </Typography>
              </React.Fragment>
            )}
          </PopupState> 
          :null
        }
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              { auth.userName ? pages.map((page) => (
                (
                  <MenuItem key={page.display} 
                    onClick={()=>handleNavToPage(page.to)}
                  >
                    <Typography textAlign="center">
                      {page.icon}{page.display}
                    </Typography>
                  </MenuItem> 
                ))) 
              : null}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href={auth.userName ?"/users":'/login'}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 600,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            CARTEAV
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            { auth.userName ? pages.map((page) => (
              ( <div style={{
                borderBottom:window.location.href.includes(page.section) ? '5px solid #009DDC':null
              }}>
                <Button
                  key={page.display}
                  onClick={()=>handleNavToPage(page.to)}
                  sx={{ my: 0,ml:1, color:window.location.href.includes(page.section) ?'#009DDC': 'white' }}
                  startIcon={page.icon}
                >

                  {page.display}
                </Button> 
                </div>
              )
            )) : null}
          </Box>

          <Box sx={{ flexGrow: 0 , display: { xs: 'none', md: 'flex'} }}>
          {auth.userName ?
              <Tooltip  title="Open settings">
                <Fab  variant='extended' onClick={handleOpenUserMenu} sx={{ backgroundColor:'white'}}>
                  <b>{auth.userName[0]}</b> 
                </Fab>
              </Tooltip> 
              :
              <Typography
                variant="h5"
                noWrap>
                Login
              </Typography> 
            } 
            {auth.userName ?  <Menu
                sx={{ mt: '5px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
               { auth.userName ? settings.map((setting) => (
                
                <MenuItem sx={{zIndex:999,display: { xs: 'none', md: 'flex'} }} key={setting} onClick={setting==='Logout' ? handleLogout: handleCloseNavMenu}>
                  <Typography textAlign="center"  style={{display:'flex',flexDirection:'row'}}>{setting==='Logout' ? <LogoutIcon />:null}{setting}</Typography>
                </MenuItem>
              )) : null}
              </Menu>  : null }
         
          </Box> 
          <Box sx={{ flexGrow: 0 , display: { xs: 'flex', md: 'none'} }}>
          {auth.userName ?
              <Tooltip  title="Open settings">
                 <Fab  size='small' onClick={handleOpenUserMenu} sx={{ backgroundColor:'white'}}>
                <b>
                    {auth.userName[0] }
                  </b> 
                </Fab>
              </Tooltip> 
              :
              <Typography
                variant="h5"
                noWrap>
                Login
              </Typography> 
            } 
            {auth.userName ?  <Menu
                sx={{ mt: '45px',display: { xs: 'flex', md: 'none'}  }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* { auth.userName ? settings.map((setting) => (
                <MenuItem key={setting} onClick={setting==='Logout' ? handleLogout: handleCloseNavMenu}>
                  <Typography textAlign="center" color={setting==='Logout' ? 'red':null}>{setting}</Typography>
                </MenuItem>
              )) : null} */}
              </Menu>  : null }
         
          </Box> 
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
