import React,{useEffect} from "react";
import {
    Grid,
    Paper,
    Button,
    Typography
  } from '@mui/material/';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { serverHost } from "../../redux/reducers/reducer";
import Swal from 'sweetalert2'
import useAuth from '../../hooks/Auth/useAuth';
import { ROLES_ARRAY } from "../../redux/context/allowedTypes";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
var printObj = function(obj) {
    var string = '';

    for(var prop in obj) {
        string+= "<div style='margin:5px;' ><b>" +prop +"</b>" + ' :   ' + obj[prop]+'</br></div>';
    }
    return string;
}
const ValidateEmail = (input) => 
{
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input))
        return (true)
    return (false)
}

const validateName = (input) =>{
    if(/^[A-Za-z\s]+$/.test(input) || (/[\u0590-\u05FF]/).test(input))
      return true;
    else
      return false;
  }
function getStyles(cloumn, columnName, theme) {
    return {
      fontWeight:
        columnName.indexOf(cloumn) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }



export default function RegisterAdmin(){
    const {auth} = useAuth();
    const theme = useTheme();
    const {sitesList} = useAuth();
    const history = useHistory()
    const [repeatPassword,setRepeatPassword] = React.useState("");
    const [checkedPass, setCheckedPass] = React.useState(true);
    const [checkEmail, setCheckedEmail] = React.useState(true)
    const [showPassword,setShowPassword] = React.useState(false);
    const [checkFirstName, setCheckFirstName] = React.useState(true)
    const [checkLastName, setCheckLastName] = React.useState(true)
    const [checkUserName, setCheckUserName] = React.useState(true)
    const [error,setError] = React.useState('');
    const [user,setUser]=React.useState({
        firstName:"",
        lastName:"",
        userName :"", 
        email:"",
        siteList:[],
        role:"",
        password:""
    })
    const [sites, setSitesList] = React.useState([]);
    const [register,setRegister] = React.useState(false)

    const clearState = ()=>{
        setUser ({
            firstName:"",
            lastName:"",
            userName :"", 
            email:"",
            password:"",
        });
        setCheckUserName(true);
        setCheckFirstName(true);
        setCheckLastName(true);
        setShowPassword(false);
        setCheckedEmail(true);
        setCheckedPass(true);
        setRepeatPassword('');
        setError('');
        setRegister(false);
    }
   


    
    const chekedPassword= (repeat_password)=>{
        setCheckedPass(repeat_password === user.password)
    }
    const handleChange = (type,event) => {
        event.preventDefault();
        setError("")
        setRegister(false)
        const input = event.target.value
        if(type ==='email') {
            const isValid=ValidateEmail(input)
            setCheckedEmail(isValid)
        }
        if(type ==='firstName' ) {
            const isValid=validateName(input )
            setCheckFirstName(isValid && input.length >= 2)
        }
        if(type ==='lastName' ) {
            const isValid=validateName(input )
            setCheckLastName(isValid && input.length >= 2)
        }
        if(type ==='userName') {
            setCheckUserName(input.length >= 2)
        }
        setUser(prevState => ({
            ...prevState,
            [type]: input
        }));
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    
      useEffect(()=>{
          if(register){
            if(!checkedPass ||  !checkFirstName || !checkLastName ||!checkUserName  ||user.password ==='' || user.email ==='' || !checkEmail ||repeatPassword === ''){
                setError("Please fill / currect all the fields!")
                setRegister(false)
            }else{
                const print = printObj(user);
                
                Swal.fire({
                    title: 'Are you sure You want to add this user ?',
                    html:`${print}`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Add!'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post(serverHost() + '/admin/regitser',{...user})
                        .then(
                            res=>{
                                if(res.status === 404){
                                    setError("Username already exists")
                                }
                                if(res.status === 500){
                                    setError("Please try again")
                                }else if(res.status === 200){
                                    clearState()
                                    Swal.fire({
                                        position: 'bottom-end',
                                        icon: 'success',
                                        title: 'User registration Successed !',
                                        showConfirmButton: true,
                                        timer: 1500
                                      })
                                      
                                }
                            }
                            )
                        .catch(error=>{
                            console.error(error);
                            setError("One or more are incorrect !")
                        })
                    }
                  })
            }
        }
    },[register])
    return (
    <>
        <Paper sx={{position:'relative',top:'1vh'}}>
            <Grid
                container
                spacing={1}
                direction={'column'}
                justify={'center'}
                alignItems={'center'}
            >
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom >
                        Register new Admin
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel required sx={{color:"#1E1E1E"}} htmlFor="firstName"><b>First Name</b></InputLabel>
                    <FilledInput
                        id="firstName"
                        type={"text"}
                        inputProps={{ maxLength: 20 }}
                        value={user.firstName}
                        onChange={(e)=>handleChange('firstName',e)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="user "
                                edge="end"
                                disabled
                            >
                        <AccountCircle />
                            </IconButton>
                        </InputAdornment>
                        }
                        label="firstName"
                    />
                    <InputLabel htmlFor="username-error" ><b style={{opacity:'0.9',fontSize:'0.8rem'}}>{checkFirstName ? null :'Must be only and at least 2 charachters !'}</b></InputLabel>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel required sx={{color:"#1E1E1E"}} htmlFor="lastName"><b>Last Name</b></InputLabel>
                    <FilledInput
                        id="lastName"
                        type={"text"}
                        value={user.lastName}
                        inputProps={{ maxLength: 20 }}
                        onChange={(e)=>handleChange('lastName',e)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="user "
                            edge="end"
                            disabled
                            >
                            <AccountCircle />
                            </IconButton>
                        </InputAdornment>
                        }
                        label="lastName"
                    />
                <InputLabel htmlFor="username-error" ><b style={{opacity:'0.9',fontSize:'0.8rem'}}>{checkLastName ? null :'Must be only and at least 2 charachters !'}</b></InputLabel>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel required sx={{color:"#1E1E1E"}} htmlFor="userName"><b>User Name</b></InputLabel>
                    <FilledInput
                        id="userName"
                        type={"text"}
                        inputProps={{ maxLength: 20 }}
                        value={user.userName}
                        onChange={(e)=>handleChange('userName',e)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="user "
                            edge="end"
                            disabled
                            >
                            <AccountCircle />
                            </IconButton>
                        </InputAdornment>
                        }
                        label="userName"
                    />
                    <InputLabel htmlFor="username-error" ><b style={{opacity:'0.9',fontSize:'0.8rem'}}>{checkUserName ? null :'Must be at least 2 charachters !'}</b></InputLabel>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel required sx={{color:"#1E1E1E"}} htmlFor="email"><b>Email</b></InputLabel>
                    <FilledInput
                        id="email"
                        type={"text"}
                        value={user.email}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e)=>handleChange('email',e)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="user "
                            edge="end"
                            disabled
                            >
                            <AccountCircle />
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Email"
                    />
                    <InputLabel error htmlFor="email-error" ><b style={{opacity:'0.9',fontSize:'0.8rem'}}>{checkEmail ? null :'Email invalid'}</b></InputLabel>
                </Grid>
    
                <Grid item xs={12}>
                    <InputLabel required htmlFor="password" sx={{color:"#1E1E1E"}}><b>Password</b></InputLabel>
                    <FilledInput
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={user.password}
                        inputProps={{ maxLength: 20 }}
                        onChange={(e)=>handleChange('password',e)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={()=>{setShowPassword(!showPassword)}}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Password"
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel required htmlFor="repeat-password" sx={{color:"#1E1E1E"}}><b>Repeat Password</b></InputLabel>
                    <FilledInput
                        id="repeat-password"
                        type={showPassword ? 'text' : 'password'}
                        inputProps={{ maxLength: 20 }}
                        value={repeatPassword}
                        onChange={(e)=>{setRepeatPassword(e.target.value);chekedPassword(e.target.value)}}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={()=>{setShowPassword(!showPassword)}}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Repeat Password"
                    />
                    <InputLabel htmlFor="password-error" ><b style={{opacity:'0.9',fontSize:'0.8rem'}}>{checkedPass ? null :'Password incorrect '}</b></InputLabel>
                </Grid>
                <Grid item xs={12}>
                    <Typography><b style={{color:'#DE1A1A'}}> {error}</b></Typography>
                </Grid>
                <Grid item xs={5}>
                    <Button fullWidth size="large" sx={{mb:2}} variant="contained" onClick={()=>setRegister(true)}> Register </Button>
                </Grid>
            </Grid>
        </Paper>
</>
    );
}