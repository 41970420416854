import { combineReducers, createStore } from 'redux'
import { serverHost } from './reducers/reducer';
import { currentMobileUserLocation } from './reducers/reducer';
import { stateReducer } from './reducers/reducer';


const rootReducer = combineReducers({
    serverHost,
    currentMobileUserLocation,
    stateReducer
})

export const store = createStore(rootReducer);
