import React from "react";
import Login from "../pages/login/login";
import RegisterAdmin from "../pages/register/registerAdmin";
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import './App.css';
import ResponsiveAppBar from "../components/Dashboard/appBar/appBar";
import { AuthProvider } from "../redux/context/authProvider";
import ProtectedRoutes from "../hooks/Auth/RequiredAuth"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {ROLES_PAGES} from "../redux/context/allowedTypes";
import Logs from "../pages/DashboardPages/siteLogs";
import Users from "../pages/DashboardPages/users";
import Sites from "../pages/DashboardPages/sites";

function App() {

  return (
  
    <Router>
      <div >
       <AuthProvider>
        <Switch>
          <Provider store={store}>
          <ResponsiveAppBar />
            {/* public Route*/}
              <Route path="/login" exact>
                <Login />
              </Route>
              <Route exact path="/" >
                <Redirect to="/login" />
              </Route>
            {/* private Route*/}
              <ProtectedRoutes allowed={ROLES_PAGES.register} path="/register_admin">
                <RegisterAdmin /> 
              </ProtectedRoutes>
              <ProtectedRoutes allowed={ROLES_PAGES.register} path="/logs">
                <Logs /> 
              </ProtectedRoutes>
              <ProtectedRoutes allowed={ROLES_PAGES.register} path="/users">
                <Users /> 
              </ProtectedRoutes>
              <ProtectedRoutes allowed={ROLES_PAGES.sites} path="/sites">
                <Sites/> 
              </ProtectedRoutes>
          </Provider>
        </Switch>
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;
