
import useAuth from "./useAuth";
import React from 'react'
import { Redirect, Route } from 'react-router'
export default function ProtectedRoutes({ children,allowed , ...rest }) {
    const {auth} = useAuth();
    return (
      <Route
        {...rest}
        render={({ location }) =>
        auth.userName ? 
          (
          // user allowed to access this route
            children
          ) : 
            ( 
              // user not authenticated
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              /> 
            )
        }
      />
    );
  }