
import  {useEffect,useState ,useRef} from 'react'
import UsersTable from "../../components/Dashboard/users/usersTable";
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import PermissionsCheck from '../../hooks/Auth/permissionCheck';
import { SCOPES,ROLES_ARRAY } from '../../redux/context/allowedTypes';
import { serverHost } from '../../redux/reducers/reducer';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import useAuth from '../../hooks/Auth/useAuth';
import RegisterUser from '../register/registerUser';
import PageHeader from '../../components/pageHeader/pageHeader';
import "../../styles/tabelsPageStyles.css";
import GenralConfirmOrCancelButton from '../../components/buttons/generalConfirmOrCancelButton';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fafafa',
    border: '2px solid #9c27b0',
    boxShadow: 10,
    p: 6,
  };

function getStyles(cloumn, columnName, theme) {
    return {
        fontWeight:
        columnName.indexOf(cloumn) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

const validateName = (input) =>{
    if(/^[A-Za-z\s]+$/.test(input) || (/[\u0590-\u05FF]/).test(input))
        return true;
    else
        return false;
}
const ValidateEmail = (input) => 
{
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input))
        return (true)
    return (false)
}


export default function Users(props){
    const serverhost = serverHost();
    const theme = useTheme();
    const {sitesList,getSitesListFunction,auth} = useAuth();
    const editRef = useRef()
    const[UsersData,setUsersData]=useState(null)
    const[allOriginUsers,setAllOriginUsers] = useState(null)
    const [addOpen ,setAddOpen] = useState(false)
    const [editOpen ,setEditOpen] = useState(false)
    const handleEditClose = () => setEditOpen(false);
    const handleAddClose = () => setAddOpen(false);
    const [checkData,setCheckData] = useState(false);
    const[dataRequired,setDataRequired] = useState(false)
    const [checkEmail, setCheckedEmail] = useState(true)
    const [checkFirstName, setCheckFirstName] = useState(true)
    const [checkLastName, setCheckLastName] = useState(true)
    const [checkUserName, setCheckUserName] = useState(true)
   
    const [editUser ,setEditUser] = useState({
        id:null,
        userName: "",
        firstName:'',
        lastName:'',
        email:"",
        role:'',
        sites_list:[]
    })
    const handleSitesList = (event) => {
        const relevant = event.target.value;
        if(!Array.isArray(editUser.sites_list) ||( editUser.sites_list[0] == null || editUser.sites_list[0] =="")){
            const arr=[relevant]
            setEditUser(prevState => ({
                ...prevState,
                ['sites_list']: arr
            }));
        }else{
            setEditUser(prevState => ({
                ...prevState,
                ['sites_list']: relevant
            }));
        }
        setCheckData(true)
    };
    const setEditUserData=(type,value) =>{
        if(type ==='email') {
            const isValid=ValidateEmail(value)
            setCheckedEmail(isValid)
        }
        if(type ==='firstName' ) {
            const isValid=validateName(value )
            setCheckFirstName(isValid && value.length >= 2)
        }
        if(type ==='lastName' ) {
            const isValid=validateName(value )
            setCheckLastName(isValid && value.length >= 2)
        }
        if(type ==='userName') {
            setCheckUserName(value.length >= 2)
        }
        setEditUser(prevState => ({
            ...prevState,
            [`${type}`]: value
        }));
        setCheckData(true)
        if(editRef){
            editRef.current.focus()
        }
    }

    useEffect(()=>{
        getSitesListFunction();
    },[])


    useEffect(()=>{
        if(checkData){
            setCheckData(false)
            if(editUser.firstName !== '' && editUser.userName !== "" &&   
            editUser.lastName !== '' &&   ROLES_ARRAY.indexOf(editUser.role) !== -1 &&
            editUser.email !== '' && Array.isArray(editUser.sites_list) &&
            editUser.sites_list[0] && editUser.sites_list[0] !=='' &&
            checkFirstName  && checkLastName  && checkUserName  && checkEmail
        ){
            setDataRequired(false);
        }else{
            setDataRequired(true);
        }
        }
    },[checkData])



    const updateUserToDB = () =>{
        setCheckData(true)
        if(!dataRequired){
            axios.post(serverhost +'/user/update',{...editUser})
            .then(res=>{
                if(res.status === 204)
                {
                    getAllUsers()
                    handleEditClose()
                    Swal.fire({
                        icon: 'success',
                        title: 'The User have been updated in DB!',
                        position:'bottom-right'
                    })
                }else{
                    handleEditClose()
                    Swal.fire({
                        icon: 'error',
                        text: 'Something went wrong!\n Try again.',
                        title:'Pay attention !',
                        position:'bottom-right'
                    })
                }
            }).catch(err => {
                console.log(err); 
                handleEditClose()
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong!\n Try again.',
                    title:'Pay attention !',
                    position:'bottom-right'
                })
            })
        }
    }
    const EditUser = (row) =>{
        console.log(row);
        if(!row) return;

        const choosenUser = {...editUser};
        const {id,firstName,lastName ,userName,role,sites_list,email} = row;
        choosenUser.id = id;
        choosenUser.firstName = firstName;
        choosenUser.lastName = lastName;
        choosenUser.userName = userName;
        choosenUser.role = role;
        choosenUser.sites_list = sites_list;
        choosenUser.email = email;
        setEditUser(choosenUser)
        setEditOpen(true)
    }

    useEffect(()=>{
        if(editOpen)
            setCheckData(true)
    },[editOpen])

    const getAllUsers = () => {
        axios.get(serverhost +'/user/getAllUsers')
        .then(res=> {
            if(res.status === 200) { 
            const allUsers =res.data
            const data =[]
            const originData = {}
            for(const User of allUsers){
                const object = {
                    id : User.user_id,
                    userName:User.user_name,
                    firstName: User.first_name ,
                    lastName: User.last_name ,
                    role:User.user_role,
                    email:User.user_email,
                    sites_list: User.sites_list
                }
                object.edit=EditUser;
                originData[`${User.User_id}`] = User;
                data.push(object)
            }
            setUsersData(data)
            
            setAllOriginUsers(originData)
        }else{
            setAllOriginUsers(null)
            setUsersData([])
        }
        }).catch(err=>{
            console.error(err)
            setAllOriginUsers(null)
            setUsersData([])
        })
    }
    
    useEffect(()=>{
        getAllUsers()   
    },[])




    return(
        <>
            <div className="bodyContainer">
                <PageHeader 
                    text={"Users"} 
                    buttonsContainer={
                    <>
                        <GenralConfirmOrCancelButton 
                            type={'confirm'} 
                            onClick={()=>{setAddOpen(true);}}
                            text={'Add users'} 
                            id={'addAdminButton'} 
                        />
                    </>
                    } 
                />
                <UsersTable data={UsersData}  />
            </div>
            
            <Modal
                open={addOpen}        
                onClose={handleAddClose}
                sx={{
                    zIndex:1000,
                    position:"absolute",
                    top:'7vh'
                }}
            >
             <Box sx={style} >
                <RegisterUser handleCloseDialog={handleAddClose} />    
                </Box>  
            </Modal>
            
            <Modal
                open={editOpen}
                
                onClose={handleEditClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography sx={{textAlign:'center',fontWeight:100,fontFamily:'-apple-system, BlinkMacSystemFont, sans-serif'}} id="modal-modal-title" variant="h5" component="h5">
                Edit User
                </Typography>
                <Typography ref={editRef} component={'div'} variant="subtitle1" sx={{display:'flex', flexDirection:'column',justifyContent:'center'}}>                          
                 <TextField
                                sx={{
                                "& fieldset": { border: 'none' },
                                }} defaultValue={editUser.id } sx={{mt:2}} disabled id="outlined-basic"  label="User ID" variant="outlined" />
                             <TextField
                                sx={{
                                "& fieldset": { border: 'none' },
                                }} defaultValue={editUser.firstName } inputProps={{ maxLength: 12}} sx={{mt:2}} required={dataRequired && editUser.firstName === ''} error={dataRequired && editUser.firstName === ''}  onChange={(e)=>{setEditUserData ( 'firstName',e.target.value)}} id="outlined-basic" type={"text"} label="First Name" variant="outlined" />
                            <InputLabel error htmlFor="firstName-error" ><b style={{opacity:'0.9',fontSize:'0.8rem',position:'relative',left:6}}>{checkFirstName ? null :'Must be only and at least 2 charachters !'}</b></InputLabel>
                             <TextField
                                sx={{
                                "& fieldset": { border: 'none' },
                                }} defaultValue={editUser.lastName } inputProps={{ maxLength: 12}}  sx={{mt:2}} required={dataRequired && editUser.lastName === ''} error={dataRequired && editUser.lastName === ''} onChange={(e)=>{setEditUserData ( 'lastName',e.target.value)}} id="outlined-basic" type={"text"} label="Last Name" variant="outlined" />
                            <InputLabel error htmlFor="lastName-error" ><b style={{opacity:'0.9',fontSize:'0.8rem',position:'relative',left:6}}>{checkLastName ? null :'Must be only and at least 2 charachters !'}</b></InputLabel>
                             <TextField
                                sx={{
                                "& fieldset": { border: 'none' },
                                }} defaultValue={editUser.userName } inputProps={{ maxLength: 20}} sx={{mt:2}} required={dataRequired && editUser.userName == null} error={dataRequired && editUser.userName == ''} onChange={(e)=>{setEditUserData ( 'userName',e.target.value)}} id="outlined-basic" type={"text"} label="User Name" variant="outlined" />
                            <InputLabel error htmlFor="username-error" ><b style={{opacity:'0.9',fontSize:'0.8rem',position:'relative',left:6}}>{checkUserName ? null :'Must be at least 2 charachters !'}</b></InputLabel>
                             <TextField
                                sx={{
                                "& fieldset": { border: 'none' },
                                }} defaultValue={editUser.email } inputProps={{ maxLength: 40}} sx={{mt:2}} required={dataRequired && editUser.email == null} error={dataRequired && editUser.email == ''} onChange={(e)=>{setEditUserData ( 'email',e.target.value)}} id="outlined-basic" type={"text"} label="Email" variant="outlined" />
                            <InputLabel error htmlFor="email-error" ><b style={{opacity:'0.9',fontSize:'0.8rem',position:'relative',left:6}}>{checkEmail ? null :'Email invalid'}</b></InputLabel>
                            <FormControl 
                                error={dataRequired &&  (!Array.isArray(editUser.sites_list) || editUser.sites_list[0] ==null ||editUser.sites_list.indexOf("") !==-1) ? true:null}
                                sx={{mt:2,minWith:240}}
                             >
                                <InputLabel id="sites_id" >Sites List</InputLabel>
                                <Select
                                    labelId="sites_id"
                                    id="sites_2"
                                    label="Sites List"
                                    
                                    multiple={ Array.isArray(editUser.sites_list) && editUser.sites_list[0] != null && editUser.sites_list[0] !== ""  ? true:null}
                                    value={Array.isArray(editUser.sites_list) && editUser.sites_list[0] != null && editUser.sites_list[0] !== ""  ? editUser.sites_list : null}
                                    onChange={handleSitesList}
                                    
                                    renderValue={Array.isArray(editUser.sites_list) ?(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            (value !== '' && value != null) &&
                                            <Chip key={value} label={value} />
                                        ))}
                                        </Box>
                                    ):null}
                                    MenuProps={MenuProps}
                                    >
                                    {sitesList.map((name) => (
                                        <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, sitesList, theme)}
                                        >
                                        {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl 
                                sx={{mt:2}}
                                error={dataRequired &&( ROLES_ARRAY.indexOf(editUser.role) === -1) ? true : null}
                            >
                            <InputLabel id="role_id"  htmlFor="role">Role</InputLabel>
                            <Select
                                labelId="role_id"
                                id="role"
                                label={'Role'}
                                sx={{fontWeight: "bold"}}
                                value={editUser.role}
                                onChange={(e)=>{setEditUserData ( 'role',e.target.value)}}
                            >
                                {ROLES_ARRAY.map((role) => (
                                    <MenuItem
                                    key={role}
                                    value={role}
                                    >
                                    {role}
                                    </MenuItem>
                                ))}
                                </Select>  
                                </FormControl>
                            <Typography  component={'div'} variant="subtitle2" sx={{display:'flex', flexDirection:'row',justifyContent:'center'}}>
                            <Button onClick={updateUserToDB} style={{marginTop:'1vh',marginRight:2}} variant={'contained'} color={'primary'} >
                                Save
                            </Button>
                            <Button onClick={handleEditClose} style={{marginTop:'1vh',marginLeft:2}} variant={'contained'} color={'error'} >
                                Close
                            </Button>
                </Typography>
                            
                        </Typography>
            </Box>
        </Modal>
        </>


    );

};
