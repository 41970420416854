
const initialState = {
    callFrameObj: null
};
export function stateReducer(state = initialState, action) {
    if (action.type === "SET") {
        state.callFrameObj = action.payload
            // alert("SET : " + state.callFrameObj)
        return state.callFrameObj
    }
    if (action.type === "GET") {
        // alert("GET : " + state.callFrameObj)
        return state.callFrameObj
    } else {
        // alert("else : " + state.callFrameObj)
        return state.callFrameObj
    }
};
export const serverHost = function() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'|| process.env.NODE_ENV === 'test') {
        return "http://localhost:5000"
    }  else {
        let server =`https://${window.location.hostname}`;
        return server;
    }
};


export const currentMobileUserLocation = function(userId) {
    return {

        //Carteav office
        carteav: {
            lat: "31.97164789",
            lon: "34.77541733"
        },

        poleg: {
            lat: "32.2520426",
            lon: "34.8660582"
        }, 
        
        //Carteav Tennis
        // carteav: {
        //     lat: "31.9714526",
        //     lon: "34.7750272"
        // },
   
        //Carteav Coffee
        carteav_cart: {
            lat: "31.9716892",
            lon: "34.7752572"
        },
        
        // gan_middle (gan_bivrit)   
        gan_beivrit: {
            lat: "31.96494669",
            lon: "34.82706602"
        },
        // gan_lettert_pazel (gan_bivrit)   
        gan_beivrit_cart: {
            lat: "31.96495307",
            lon: "34.82630993"
        },

        // imecar 
        imecar: {
            lat: "36.84124718",
            lon: "30.61062438"
        },
        // imecar
        imecar_cart: {
            lat: "36.8413299",
            lon: "30.6106870"
        }
    }
};